import React, { Component } from 'react'

export default class Title extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const defaultTitle = 'Zilla Parishadh Digital Center, Sylhet | জেলা পরিষদ ডিজিটাল সেন্টার, সিলেট';
        document.title = this.props.title + ' | '+defaultTitle;
    }
  render() {
    return (
      <></>
    )
  }
}

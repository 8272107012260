import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
export default class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <section className='home_about_section py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-sm-6 text-center text-sm-start'>
                <div className='home_about_text'>
                  <h1 className='py-3'>  <span> ডিজিটাল সেন্টার  </span>  <br /> {this.props.settings.about_title} </h1>
                  <h5 className='pb-3'> {this.props.settings.about_description} </h5>
                  <NavLink to="/admission" className='btn  btn-danger btn-lg green_button'>Apply Now</NavLink>
                </div>
              </div>
              <div className='col-sm-6 d-none d-sm-block'>
                <img src={this.props.settings.about_image} ImageAlt='ডিজিটাল সেন্টার' />
              </div>
            </div>
          </div>
        </section>


      </>
    )
  }
}

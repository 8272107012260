import React, { Component } from 'react'
import Hero from '../components/Home/Hero';
import Title from '../components/Title';
import LodingBox from '../components/LoadingBox';
import AlertDanger from '../components/AlertDanger';
import '../components/Globals';
import Axios from 'axios';

export default class Apply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Auth: false,
            loading: false,
            errorMessage: false,
            SuccessMessage: false,
            message: '',
            alertBox: 'danger',
            submitData: {
                name: '',
                email: '',
                phone: '',
                fathers_name: '',
                mothers_name: '',
                address: '',
                permanent_address: '',
                gender: '',
                religion: '',
                birth_or_nid: '',
                date_of_birth: '',
                guardian_name: '',
                guardian_phone: '',
                edu_q: '',
                course: null,
                last_institute_name: '',
                class_preferred: '',
                with_leaptop: '',
                image: ''
            },
            errors: {},
        };
    }

    submitProcess = async () => {

        this.setState({ loading: true });

        let formData = new FormData();    //formdata object
        formData.append('name', this.state.submitData.name);
        formData.append('email', this.state.submitData.email);
        formData.append('phone', this.state.submitData.phone);
        formData.append('fathers_name', this.state.submitData.fathers_name);
        formData.append('mothers_name', this.state.submitData.mothers_name);
        formData.append('address', this.state.submitData.address);
        formData.append('permanent_address', this.state.submitData.permanent_address);
        formData.append('edu_q', this.state.submitData.edu_q);
        formData.append('last_institute_name', this.state.submitData.last_institute_name);
        formData.append('with_leaptop', this.state.submitData.with_leaptop);
        formData.append('class_preferred', this.state.submitData.class_preferred);
        formData.append('course', this.state.submitData.course);
        formData.append('gender', this.state.submitData.gender);
        formData.append('religion', this.state.submitData.religion);
        formData.append('birth_or_nid', this.state.submitData.birth_or_nid);
        formData.append('date_of_birth', this.state.submitData.date_of_birth);
        formData.append('guardian_name', this.state.submitData.guardian_name);
        formData.append('guardian_phone', this.state.submitData.guardian_phone);
        formData.append('image', this.state.submitData.image);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        await Axios.post(global.apiUrl + '/apply', formData)
            .then(response => {
                this.setState({ loginDetails: response.data });
                //if not logged in
                if (response.data.status == 'ERROR') {
                    this.setState({ errorMessage: true });
                    this.setState({ errors: response.data.errors });
                    this.setState({ message: response.data.message });
                    this.setState({ loading: false });
                } else {

                    this.setState({ errorMessage: true });
                    this.setState({ message: response.data.message });
                    this.setState({ alertBox: 'success' });

                    this.setState({ loading: false });
                    document.getElementById("submit_form").reset();

                }
                this.setState({ loading: false });

            })
            .catch(function (error) {

            });
    }

    handleSubmit = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        const answer = window.confirm("Are you sure ?");
        if (answer) {
            await this.submitProcess();
        } else {
            this.setState({ loading: false });
        }

    }

    dataHandler = (e) => {
        const newData = this.state.submitData;
        newData[e.target.name] = e.target.value;
        this.setState({ submitData: newData });

    }

    // On file select (from the pop up)
    onFileChange = (e) => {
        // Update the state
        const newData = this.state.submitData;
        newData[e.target.name] = e.target.files[0];
        this.setState({ submitData: newData });
        console.log(newData);
    }

    render() {
        const courses = this.props.courses;
        return (
            <>
                <Title title="Online Application" />

                <section className=" py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="service_section_title">Apply Online</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 mt-5 hasLoading'>

                                {this.state.errorMessage ? <AlertDanger message={this.state.message} errors={this.state.errors} alertBox={this.state.alertBox} ></AlertDanger> : ''}
                                {this.state.loading ? <LodingBox></LodingBox> : ''}

                                <>
                                    {this.state.alertBox == 'success' ? '' : <>

                                        <form id="submit_form" onSubmit={this.handleSubmit} >
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Full Name</label>
                                                        <input name='name' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder='Full  Name' required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Phone</label>
                                                        <input name='phone' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder='Phone' required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input name='email' onChange={(e) => this.dataHandler(e)} type="email" className="form-control" placeholder='Email' required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Fater's Name</label>
                                                        <input name='fathers_name' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Father's name" required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Mother's Name</label>
                                                        <input name='mothers_name' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Mother's name" required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Present Address</label>
                                                        <input name='address' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Address" required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Permanent Address</label>
                                                        <input name='permanent_address' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Permanent Address" required />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Gender</label>
                                                        <select onChange={(e) => this.dataHandler(e)} name="gender" id="gender" className="form-select " placeholder="Gender" required>
                                                            <option value="">Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Course</label>
                                                        <select onChange={(e) => this.dataHandler(e)} name="course" id="course" className="form-select " placeholder="Course" required>
                                                            <option value="">Select Course</option>


                                                            {courses.map((course, index) => {
                                                                return (
                                                                    <option value={course.id} key={index}>
                                                                        {course.title}
                                                                    </option>
                                                                )
                                                            })}

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Religion</label>
                                                        <select onChange={(e) => this.dataHandler(e)} name="religion" id="religion" className="form-select" placeholder="Religion" required>
                                                            <option value="">Select Religion</option>
                                                            <option value="Muslim">Muslim</option>
                                                            <option value="Hindu">Hindu</option>
                                                            <option value="Buddhist">Buddhist</option>
                                                            <option value="Christians">Christians</option>
                                                            <option value="Yehudi">Yehudi</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Birth Or NID Number</label>
                                                        <input name='birth_or_nid' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Birth Or NID Number" />
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Date Of Birth <small>Ex: <span className='text-danger'>01/07/1993</span> </small> </label>
                                                        <input name='date_of_birth' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="DD/MM/YY" required />
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Guardian  Name</label>
                                                        <input name='guardian_name' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Guardian  Name" required />
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Guardian  Phone</label>
                                                        <input name='guardian_phone' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Guardian  Phone" required />
                                                    </div>
                                                </div>

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Educational Qualification</label>
                                                        <input name='edu_q' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Educational Qualification" required />
                                                    </div>
                                                </div>
                                                {/* <div className='col-sm-6'>
                                            <div className="mb-3">
                                                <label className="form-label">Last Institute Name</label>
                                                <input name='last_institute_name' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder="Last Institute Name" required />
                                            </div>
                                        </div> */}

                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Institute Name</label>
                                                        <select onChange={(e) => this.dataHandler(e)} name="last_institute_name" id="last_institute_name" className="form-select" required>
                                                            <option value="">Select Institute</option>
                                                            <option value="Sylhet Polytechnic Institute (SPI)">Sylhet Polytechnic Institute (SPI)</option>
                                                            <option value="Moulvibazar Polytechnic Institute (MPI)">Moulvibazar Polytechnic Institute (MPI)</option>
                                                            <option value="Islamibank Institute Of Technology (IBIT)">Islami Bank Institute Of Technology (IBIT)</option>
                                                            <option value="Sunamganj Polytechnic Institute">Sunamganj Polytechnic Institute</option>
                                                            <option value="Sylhet Professional Technical Institute (SPTI)">Sylhet Professional Technical Institute (SPTI)</option>
                                                            <option value="Headway Engineering Institute">Headway Engineering Institute</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Class Prefered</label>
                                                        <select onChange={(e) => this.dataHandler(e)} name="class_preferred" id="class_preferred" className="form-select" required>
                                                            <option value="">Select..</option>
                                                            <option value="Online">Online Class</option>
                                                            <option value="Lab">Zilla Parishadh Computer Lab</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">If you have a laptop, can you bring it to class?</label>
                                                        <select onChange={(e) => this.dataHandler(e)} name="with_leaptop" id="with_leaptop" className="form-select" required>
                                                            <option value="">Select..</option>
                                                            <option value="No_Leaptop">I have no laptop</option>
                                                            <option value="Yes">Yes, I will bring my laptop</option>
                                                            <option value="No">No i can't </option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className='col-sm-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label">Profile image<small>(300px X 300px)</small></label>
                                                        <input onChange={this.onFileChange} type="file" name="image" id="image" className="form-control" placeholder="Profile image" />
                                                    </div>
                                                </div>

                                                <div className='col-sm-12'>

                                                    <button type="submit" className="btn btn-primary red_button">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </>}

                                </>


                            </div>

                        </div>
                    </div>
                </section>

            </>
        )
    }
}
